import React from 'react';
import Feature from '../../components/feature/Feature';
import Animate from '../animate/Animate';
import Cs from '../../assets/css.png'; 

import './features.css';

const featuresData = [
  {
    title: 'Honesty',
    text: 'We are always searching for the oppourtunaty to make a differnece, and we feel strongly that we can only make a differnece when we have the oppourtunaty to give honst and straightforward advice.',
  },
  {
    title: 'Empathy',
    text: "We care and we want to understand our clients. We'll listen to our clients and our outmost to understand our clients needs.",
  },
  {
    title: 'Competence',
    text: 'We are experts in our field, equipped with the knowledge, skills, and experience to deliver exceptional results. Our team stays up-to-date with the latest industry trends and best practices, ensuring that our solutions are backed by a solid foundation of competence. Trust us to handle your project with professionalism and expertise, exceeding your expectations every step of the way.',
  },
  {
    title: 'Creativity',
    text: 'We embrace creativity as a driving force behind innovation and problem-solving. Our team thrives on thinking outside the box, coming up with unique and imaginative solutions tailored to your specific needs. By infusing creativity into our work, we deliver engaging and memorable experiences that captivate your audience and set you apart from the competition. Let our creative approach breathe new life into your projects and unleash their full potential.',
  },
];

const Features = () => (
 
   <div className="AAA">

<div className="AAAA">

<div className="services__feature">
        
        </div>

        </div>

        <Animate>
    <div className="services__features-heading">
      <h1 className="gradient__text" id="about-us-header">The Four Cornerstones of Full of Beans Media</h1>
 
    </div>
    </Animate>
 
  <div className="services__features section__padding" id="features">

   <div className="flex-box">
  
    <div className="cornerstones_a">
      {featuresData.map((item, index) => (
        <Animate key={item.title + index}>
          <div className="animation-div">
            <Feature title={item.title} text={item.text} />
          </div>
        </Animate>
      ))}
    </div>
  
  </div>
  
  <div className="we-match">
    <div className="we-match-header">
    <h1 className="gradient__text">We aim to establish a strong fit with our partners</h1>
    <p>Realistically, we understand that we may not be a perfect match for everyone, but we're eager to explore the possibilities. We are always open to initiating a conversation, so let's connect and discuss our compatibility. Meanwhile, there are certain areas in which we don't operate.</p>
    </div>
    <div className="we-match-content">
    <h2>What we don't do</h2>
    <div>


      <div className="icon-and">
        <div className="icon-no"><svg xmlns="http://www.w3.org/2000/svg" className="icon-no-style" viewBox="0 0 16 16">
        <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        </div>
        <p>PR</p>
      </div>



      <div className="icon-and">
        <div className="icon-no"><svg xmlns="http://www.w3.org/2000/svg" className="icon-no-style" viewBox="0 0 16 16">
        <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        </div>
        <p>Tie you up in long contacts</p>
      </div>

      <div className="icon-and">
        <div className="icon-no"><svg xmlns="http://www.w3.org/2000/svg" className="icon-no-style" viewBox="0 0 16 16">
        <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        </div>
        <p>Take a procentage of your revenue</p>
      </div>

      <h2>What we love to do</h2>

      <div className="icon-and">
        <div className="icon-no">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-love" viewBox="0 0 16 16">
         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
         </svg>
        </div>
        <p>lead generation</p>
      </div>

      <div className="icon-and">
        <div className="icon-no">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-love" viewBox="0 0 16 16">
         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
         </svg>
        </div>
        <p>Growing your E-commerce ROI</p>
      </div>

      <div className="icon-and">
        <div className="icon-no">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-love" viewBox="0 0 16 16">
         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
         </svg>
        </div>
        <p>Developing strategy that will take your business to the next level</p>
      </div>

    </div>
    </div>
  </div>



  </div>

  <div className="mentality-ref">

  <div className="mentality">



  <div className="m-header"><h1 className="gradient__text">The Recipy To Success</h1><p>Our 5 step recipy for increased performance</p></div>



    <div className="m-list">



{/*<div className="line"></div>*/}

<div className="line-steps">

<Animate><div className="number-and-p"><h1 className="gradient__text">01</h1><div id="line-1"><p className="line-p">Set goals</p><p className="line-pp">First we need to think through where you want to go. Hopefully we can help you realize that you can go even further than you initially realized.</p></div></div></Animate>
<Animate><div className="number-and-p"><h1 className="gradient__text">02</h1><div id="line-2"><p className="line-p">Contruct and Execute</p><p className="line-pp">When we have a goal we can start working on the way to get there.</p></div></div></Animate>

<Animate><div className="number-and-p"><h1 className="gradient__text">03</h1><div id="line-3"><p className="line-p">Observe and understand</p><p className="line-pp">Understanding and observing trends and patterens in data is key to improve and optimize your campaigns to reach the maximum profitability.</p></div></div></Animate>

<Animate><div className="number-and-p" id="line-4-s"><h1 className="gradient__text">04</h1><div><p className="line-p" id="line-4">Add More Flavor</p><p className="line-pp" >As your campaigns start to perform well and increase your ROI it's always good to look for new ideas which may add more profit to your exsisting performance-machine. We look to discover patteren and trends in your data that can inspire new strategies that can make a positive impact on your campaigns. The data should lead the way forward and guide the execution. Stagnation is the enemy of growth and increase in your performance.</p></div></div></Animate>
<Animate><div className="number-and-p" id="line-5-s"><h1 className="gradient__text">05</h1><div><p className="line-p" id="line-5">Scale And Repeat</p><p className="line-pp">We always look for what works and what creates opportunatise and performance for our clients. Depending on the state of your business and how fast you are looking to scale, we'll advise you in the best to scale and not just perform well, but actively help your grow towards the next level. </p></div></div></Animate>








</div>
</div>
</div>
<section class="services section__margin" id="ref-container">
<Animate><div class="ref-flex">
     <div class="ref-img"></div>
          <div class="ref-text">
             <div class="quote">
               <div class="quotation-mark-v1">“</div>
               <h1 class="quote-h1">It has been a good experience collaborating with Nick from Full of Beans Media. He has listened to my ideas and provided good suggestions along the way.</h1>
               <span className="quotation-mark-v3">”</span>
         
              </div>
              <div className="sub-quote">
                <h2 class="quote-h2">Dan Hulgaard</h2>
                <h2 class="quote-h3">CEO of Hulgaard Teknik & Service</h2>
                {/*<button class="quote-button">Se case her</button>*/}
              </div>
            </div>
  
        </div></Animate>
</section>
</div>
  </div>
);

export default Features;
