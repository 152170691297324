import React, { useState, useRef, useEffect } from 'react';
import Hmv from '../../components/hmv/Hmv';
import './contact.css';
import Feature from '../../components/feature/Feature';
import Animate from '../animate/Animate';
import meetingphoto from '../../assets/meeting-photo.png'; 

const Contact = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(Array(6).fill(''));
  const [nameAnswer, setNameAnswer] = useState('');
  const [companyNameAnswer, setCompanyNameAnswer] = useState('');
  const [focusedButtons, setFocusedButtons] = useState([]);
  const [showHmv, setShowHmv] = useState(false);
  const [userInputs, setUserInputs] = useState({
    name: '',
    companyName: '',
    role: '',
    email: '',
    website: '',
    tellUsMore: '',
    selectedServices: [],
  });
  const inputRef = useRef(null);

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setUserAnswers(Array(6).fill(''));
    setNameAnswer('');
    setCompanyNameAnswer('');
    setFocusedButtons([]);
    setShowHmv(false);
    setUserInputs({
      name: '',
      companyName: '',
      role: '',
      email: '',
      website: '',
      tellUsMore: '',
      selectedServices: [],
    });
  }, []);

  const generateQuestionHeader = () => {
    switch (currentQuestionIndex) {
      case 0:
        return 'What is your name?';
      case 1:
        return `What is the name of your company?`;
      case 2:
        return `${nameAnswer}, what is your role in ${companyNameAnswer}?`;
      case 3:
        return `On what e-mail can we reach you?`;
      case 4:
        return `Does ${companyNameAnswer} have a website?`;
      case 5:
        return 'Tell us more about what you are searching for';
      default:
        return '';
    }
  };

  const questions = [
    { header: generateQuestionHeader(), text: "Type your answer here...", type: 'text' },
    { header: generateQuestionHeader(), text: "Type your answer here...", type: 'text' },
    { header: generateQuestionHeader(), text: "Type your answer here...", type: 'text' },
    { header: generateQuestionHeader(), text: "Type your e-mail here", type: 'email' },
    { header: generateQuestionHeader(), text: "Type your website here?", type: 'url' },
    { header: generateQuestionHeader(), text: "Type your answer here...", type: 'text' },
  ];

  const buttonOptions = ['Google Ads', 'Bing Ads', 'Facebook Ads', 'LinkedIn Ads', 'SEO'];

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length) {
      if (inputRef.current.checkValidity()) {
        const answer = userAnswers[currentQuestionIndex];
        switch (currentQuestionIndex) {
          case 0:
            setNameAnswer(answer);
            break;
          case 1:
            setCompanyNameAnswer(answer);
            break;
          default:
            break;
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        inputRef.current.reportValidity();
      }
    }
  };

  const handleButtonSelection = (option) => {
    if (focusedButtons.includes(option)) {
      setFocusedButtons(focusedButtons.filter(item => item !== option));
  
      const updatedUserInputs = { ...userInputs };
      updatedUserInputs.selectedServices = updatedUserInputs.selectedServices.filter(item => item !== option);
      setUserInputs(updatedUserInputs);
    } else {
      setFocusedButtons([...focusedButtons, option]);
  
      const updatedUserInputs = { ...userInputs };
      updatedUserInputs.selectedServices = [...updatedUserInputs.selectedServices, option];
      setUserInputs(updatedUserInputs);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (currentQuestionIndex < questions.length) {
        nextQuestion();
      } else {
        handleButtonOk();
      }
    }
  };

  const handleAnswerChange = (event) => {
    let newValue = event.target.value;

    if (questions[currentQuestionIndex].type === 'url') {
      if (!newValue.startsWith('https://')) {
        newValue = 'https://' + newValue;
      }
    }

    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = newValue;
    setUserAnswers(updatedAnswers);

    const updatedInputs = { ...userInputs };

    switch (currentQuestionIndex) {
      case 0:
        updatedInputs.name = newValue;
        break;
      case 1:
        updatedInputs.companyName = newValue;
        break;
      case 2:
        updatedInputs.role = newValue;
        break;
      case 3:
        updatedInputs.email = newValue;
        break;
      case 4:
        updatedInputs.website = newValue;
        break;
      case 5:
        updatedInputs.tellUsMore = newValue;
        break;
      default:
        break;
    }

    setUserInputs(updatedInputs);
  };

  const handleButtonOk = () => {
    if (focusedButtons.length > 0) {
      setShowHmv(true);
    } else {
      alert("Please select at least one service.");
    }
  };

  const [verificationSuccess, setVerificationSuccess] = useState(false);


  const handleVerificationSuccess = async (selectedImages) => {
    console.log('Verification success! Selected images:', selectedImages);
  
    const response = await fetch('contact-en.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        name: userInputs.name,
        companyName: userInputs.companyName,
        role: userInputs.role,
        email: userInputs.email,
        website: userInputs.website,
        tellUsMore: userInputs.tellUsMore,
        selectedServices: userInputs.selectedServices.join(','),
        currentPageURL: window.location.href, // Include the current URL
      }),
    });
  
    const result = await response.text();
    console.log('Server response:', result); // Log the response content
  
    if (result === 'success') {
      console.log('Email sent successfully');
      setVerificationSuccess(true);

    // Trigger the Google Analytics event
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'engelskForsideKontaktformular', {
        'event_category': 'kontakt',
        'event_label': 'kontaktformular'
      });
    }



    } else {
      console.error('Error sending email');
      // Handle error case
    }
  };
  
  // open and close calendar box as an iFrame

  const [showCalendar, setShowCalendar] = useState(false);


  const calendarBoxStyle = {
    display: showCalendar ? 'block' : 'none',
  };
  

  
  
  return (

     
    <div className="gpt3__header section__padding" id="contact">
    <div className="contact-container">
        
      <div className="gpt3__header-content__v2">
  
        <div className="FOBM__intro__contact__form">
          <Animate>
          <h1 className="gradient__text">Let's smash it right out of the park!</h1>
          </Animate>
          <Animate>
          <p className="form__p">Let's have a talk about your goals and aspirations and see if we are a good fit.</p>
          </Animate>
          <Animate>
           <p className="form__p">Fill out our questionnaire; it takes less than 1 minute!</p>
           </Animate>

           <Animate>
           <button className="book-meeting"   onClick={() => setShowCalendar(!showCalendar)}>
            
            
           
            <div className="button-content">
              <div className="bm-txt-p">
           <p id="p-one">Book a Meeting faster here</p>
           <p id="p-two">Book a meeting in Nick's calendar</p>
         
           </div>
           <img id="meeting-photo" src={meetingphoto}></img>
           <div className="button-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="icon-arrow" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
            </svg>
            </div>
           </div>
          
          </button>

          <div id="calendar-box" style={calendarBoxStyle}> 

          <div className="overlay">

          <div className="box-and-arrow">
            <div className="frame">
          <iframe id="frame" src="https://calendly.com/fullofbeansmedia/30min"></iframe>
          </div>
          <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="calendar-close"
            viewBox="0 0 16 16"
            onClick={() => setShowCalendar(false)} // Add this onClick handler
                >
             <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
             </svg>
            </div>
          </div>
          </div>
          </div>
           </Animate>
        </div>
        
        <div className="gpt3__header-content__input">
          {verificationSuccess ? (
            <div className="success-slide">
              <h2>Thank you for contacting us!</h2>
              {/* Add any content you want to show on the success slide/page */}
            </div>
          ) : (
            <div>
              {currentQuestionIndex < questions.length ? (
                <div>
                  <p className="question-header">{questions[currentQuestionIndex].header}</p>
                  <input
                    ref={inputRef}
                    type={questions[currentQuestionIndex].type}
                    placeholder={questions[currentQuestionIndex].text}
                    value={userAnswers[currentQuestionIndex]}
                    onChange={handleAnswerChange}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <div className="underline"></div>
                  <button type="button" onClick={nextQuestion}>OK</button>
                </div>
              ) : (
                <div>
                  {!showHmv && (
                    <div className="button-options">
                      <p>Which of our skills do you need in your toolbox?</p>
                      {buttonOptions.map((option, index) => (
                        <button
                          key={index}
                          onClick={() => handleButtonSelection(option)}
                          className={focusedButtons.includes(option) ? 'selected' : ''}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  )}
                  {!showHmv && (
                    <button type="button" onClick={handleButtonOk} className="next-button">OK</button>
                  )}
                </div>
              )}
              {showHmv && (
                <Hmv onVerificationSuccess={handleVerificationSuccess} />
              )}
            </div>
          )}
        </div>
        
      </div>
      </div>
    </div>
   
  );
  
};

export default Contact;
