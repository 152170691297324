import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom'
import video from '../../assets/video.mp4'; 
import thumb from '../../assets/thumb.png'; 
import cap from '../../assets/captions.srt'; 



import Animate from '../animate/Animate';

import './header.css';


const Header = () => {

  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscription = async () => {
    const apiUrl = '/email.php';
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        try {
          const data = await response.json();
  
          if (data.success) {
            setSubscriptionStatus('Subscribed successfully!');
            setEmail(''); // Clear the input field on success
            alert('Subscribed successfully!'); // Show an alert
          } else {
            setSubscriptionStatus('Subscription failed. Please try again.');
            console.error('Subscription failed:', data.error); // Log error to console
            alert('An error occurred. Please try again later.');
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          setSubscriptionStatus('An error occurred. Please try again later.');
          alert('An error occurred. Please try again later.');
        }
      } else {
        console.error('Server responded with an error:', response.status);
        setSubscriptionStatus('An error occurred. Please try again later.');
        alert('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscriptionStatus('An error occurred. Please try again later.');
      alert('An error occurred. Please try again later.');
    }
  };




  const [yearsOfExperience, setYearsOfExperience] = useState(0);



   // Function to animate the counter
   const animateCounter = (start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setYearsOfExperience(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    // Start the counter animation
    animateCounter(0, 6, 5000); // Replace 10 with your actual years of experience
  }, []); // Run only once when the component mounts


  const [totalAccounts, setTotalAccounts] = useState(0);



  // Function to animate the counter
  const counter = (start, end, duration) => {
   let startTimestamp = null;
   const step = (timestamp) => {
     if (!startTimestamp) startTimestamp = timestamp;
     const progress = Math.min((timestamp - startTimestamp) / duration, 1);
     setTotalAccounts(Math.floor(progress * (end - start) + start));
     if (progress < 1) {
       window.requestAnimationFrame(step);
     }
   };
   window.requestAnimationFrame(step);
 };

 useEffect(() => {
   // Start the counter animation
   counter(0, 1000, 5000); // Replace 10 with your actual years of experience
 }, []); // Run only once when the component mounts
  

  return (
    <Animate>
      <section id="header-section">
    <div className="animation-div">
    
      {/* Other content */}
      <div className="a__header section__padding" id="home">
        <div className="a__header-content">
        <h1 className="gradient__text">Build Your Digital Presence</h1> 
        <p>We specialize in Digital Marketing and action-oriented digital strategies that generate leads and drive sales.</p>

          <div className="a__header-content__input__v2">

              {/* Apply animation to this input field */}
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              
              />
      
            <button type="button" onClick={handleSubscription}>
              Subscribe to our mailing list &nbsp;&nbsp;<span className="letter-emoji">✉️</span>
            </button>
          </div>

          {/* Removed subscription-status <div> */}
        </div>

        <div className="a__header-image">
           {/* INSERT INTRO VIDEO HERE */}
           <video id="videoPlayer" controls controlsList="nodownload" poster={thumb} oncontextmenu="return false;">
           <source src={video} type="video/mp4" />
           <track label="English" kind="subtitles" src={cap} default />
           </video>

        </div>


      

      </div>

      <div className="experience">
      <div className="years-of-exp"><p>
              <span id="years">{yearsOfExperience}+</span> Years of Experience</p>
            </div>

            <div className="years-of-exp"><p>
              <span id="years">{totalAccounts}+</span> Google Ads accounts served</p>
            </div>
      </div>

     
   
    </div>

  
    </section>
    </Animate>
  );
};

export default Header;
