import React, { useState, useEffect, useRef } from 'react';
import karenImg from '../../assets/karen.webp'; 

import './Spk.css';


const Spk = () => {
  

    return (

<section className="services section__margin" id="karen-container">
  <div className="karen-flex">
  <img src={karenImg} className="karen-img" alt="Karen Krog Nielsen" />
          <div class="karen-text">
             <div className="quote-karen">
               <div className="quotation-mark-v1-karen">“</div>
               <h1 className="quote-h1-karen">Nick is the best partner you could wish for when it comes to digital marketing</h1>
               <span className="quotation-mark-v3-karen">”</span>

              </div>
              <div className="sub-quote-karen">
                <h2 className="quote-h2">Karen Krog Nielsen</h2>
                <h2 className="quote-h3">CEO</h2>
                {/*<button class="quote-button">Se case her</button>*/}
              </div>
            </div>
  
        </div>
</section>

     
    )

  };
  
  export default Spk;
  