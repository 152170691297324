
import './Privacy.css';

const Privacy = () => {

  
    return (

        <div className="privacy-div">

<h1 className="test">Privacy Policy</h1>

<p>Last Updated: October 5, 2023</p>

<p>Full of Beans Media ("we," "our," or "us") is a digital marketing performance agency operated and owned by Int Solutions ApS ("Int Solutions," "our company," or "the company"). We are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website, engage with our services, or otherwise interact with us. By using our website or services, you consent to the practices described in this policy.</p>

<h2>Information We Collect</h2>
<p>We may collect the following types of information:</p>

<h3>1.1 Personal Information</h3>
<ul>
   
<li>Contact Information: Your name, email address, phone number, and postal address. </li>
<li>Account Information: If you create an account with us, we collect information such as your username and password.</li>
<li>Payment Information: If you make payments for our services, we may collect payment card information or other financial details.</li>
</ul>

<h3>1.2 Usage Information</h3>

<ul>
<li>Website Usage: Information about how you use our website, including your IP address, browser type, device information, and pages visited.</li>
<li>Log Data: We may automatically collect data about your interactions with our website and services, such as access times, and referring websites.</li>
</ul>

<h3>1.3 Cookies and Tracking Technologies</h3>

<p>We use cookies and similar tracking technologies to collect information about your browsing behavior and preferences. You can manage your cookie preferences through your browser settings.</p>

<h3>1.4 Client Data</h3>

<p>If you are a client or partner, we may collect and store data related to your marketing campaigns, including performance metrics and analytics.</p>

<h3>How We Use Your Information</h3>

<p>We use your information for the following purposes:</p>

<h3>2.1 Provide Services</h3>
<ul>
<li>To provide, maintain, and improve our digital marketing services.</li>
<li>To communicate with you about your account, inquiries, and requests.</li>
<li>To process payments for our services, where applicable.</li>
</ul>

<h3>2.2 Analytics and Personalization</h3>
<ul>
<li>To analyze and improve our website, services, and user experience.</li>
<li>To personalize content and recommendations based on your preferences.</li>
</ul>

<h3>2.3 Marketing and Promotions</h3>

<ul>
<li>To send you marketing communications about our services, promotions, and events, with your consent.</li>
<li>To provide targeted advertising based on your interests and online behavior, where applicable.</li>
</ul>


<h3>Changes to this Privacy Policy</h3>
<p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes and update the "Last Updated" date at the beginning of this policy.</p>

<h3>Contact Us</h3>
<p>If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at:</p>

<h3>Full of Beans Media</h3>
<p>Email: info@fullofbeansmedia</p>


<p>Thank you for choosing Full of Beans Media. We value your privacy and trust in us.</p>

        </div>
   
    );
  };
  
  export default Privacy;
  