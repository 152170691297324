import React, { useState, useEffect } from 'react';
import './Country.css'; 
import flag from '../../assets/flag-dk.webp'; 

const Country = () => {
  const [showDiv, setShowDiv] = useState(false);
  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(timezone);
    console.log('Detected timezone:', timezone);

    // Check if the detected timezone corresponds to Denmark
    if (timezone === 'Europe/Copenhagen') {
      setShowDiv(true);
    }
  }, []);

  const handleClosePopup = () => {
    setShowDiv(false); // Hide the popup when the close icon is clicked
  };

  return (
    <div>
      {showDiv && (
        <div className="popup">
          <a href="https://fullofbeansmedia.com/da" className="popup-v2">
            <img id="dk-flag" src={flag} alt="Danish Flag" />
            <h2 id="h1-flag">Skift til dansk her</h2>
            <svg xmlns="http://www.w3.org/2000/svg" className="arrow-icon" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
            </svg>
          </a>
          <div className="close-div" onClick={handleClosePopup}> {/* Add onClick event handler */}
            <svg xmlns="http://www.w3.org/2000/svg" className="close-icon" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
          </div>
        </div>
      )}
      {userTimezone && <p>Detected timezone: {userTimezone}</p>}
    </div>
  );
};

export default Country;
