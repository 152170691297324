
import bg from '../../assets/tablogo.png'; // Update the path to your bg.png
import './Login.css';

const Login = () => {

  
    return (
   <div className="login">

    

    <h1>Dashboard Login</h1>

   
 
    <input
    type="email"
    placeholder="Enter your email here..."
    />

    <input
    type="password"
    placeholder="Enter your password here..."
    />

    <button id="sign-in">Sign in</button>

    <hr></hr>
    
    <div className="logo-note">
    <img id="logo" src={bg} alt="Background" />
   <p>Developed by Full of Beans Media</p>
   </div> 

   </div>
    );
  };
  
  export default Login;
  