import React, { useState, useEffect } from 'react';
import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';
import './Hmv.css';

const Hmv = ({ onVerificationSuccess }) => {
  const [imageOrder, setImageOrder] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage2Count, setSelectedImage2Count] = useState(0); // New state

  const image2v1 = 'image2';
  const image2v2 = 'image2';
  const image2v3 = 'image2';
  const image2v4 = 'image2';
  const image2v5 = 'image2';

  useEffect(() => {
    const totalImages = 25;
    const image1Count = 20;

    const images = Array(image1Count).fill('image1').concat([
      image2v1, image2v2, image2v3, image2v4, image2v5
    ]);
    
    const shuffledImages = images.sort(() => Math.random() - 0.5);

    setImageOrder(shuffledImages);
  }, [image2v1, image2v2, image2v3, image2v4, image2v5]);

  const requiredImage2Count = 5; // Number of 'image2' variations to count

  useEffect(() => {
    if (selectedImage2Count === requiredImage2Count) {
      // Verification success
   
      // Pass the necessary information back to the parent component
      onVerificationSuccess(selectedImages); // Pass the selected images
    }
  }, [selectedImage2Count]);
  

  const handleImageClick = (image) => {
    if (selectedImages.length < 6) {
      if (image === 'image1') {
        setSelectedImages([]);
        setSelectedImage2Count(0); // Reset count on 'image1' click
        const shuffledImages = imageOrder.sort(() => Math.random() - 0.5);
        setImageOrder(shuffledImages);
      } else if (image.startsWith('image2v') && !selectedImages.includes(image)) {
        setSelectedImages([...selectedImages, image]);
        setSelectedImage2Count(count => count + 1); // Increment 'image2' count
      }
    }
  };
  
  useEffect(() => {
    const selectedImage2s = selectedImages.filter(img => img.startsWith('image2v'));
    console.log(selectedImage2s);
  }, [selectedImages]);

  return (
    <div className="verification-test">
      <h2 className='Hmv__header__h1'>Human Verification</h2>
      <p className="question-header">Select the 5 astronauts</p> 
      <div className="image-grid">
        {imageOrder.map((image, index) => (
          <div
            key={index}
            className={`image ${selectedImages.includes(image) ? 'selected' : ''}`}
            onClick={() => handleImageClick(image)}
          >
            {image === 'image1' ? (
              <img src={image1} alt="Image 1" />
            ) : (
              <img
                src={image2}
                alt={`Image 2-${index}`}
                key={`image2-${index}`}
                id={`image2v${index}`} // Assign a unique id based on index
                onClick={() => handleImageClick(`image2v${index}`)}
                className={selectedImages.includes(`image2v${index}`) ? 'selected' : ''}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default Hmv;
