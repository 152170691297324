import React, { useState, useEffect } from 'react';
import './PopupContent.css';

const PopupContent = ({ title, slides, onClose }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  

  const handleNextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };
  

  const handlePrevSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const scrollToTop = () => {
    const highlightedWord = document.getElementsByClassName("h1-to-top")[0];
    if (highlightedWord) {
      highlightedWord.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const preventScroll = (e) => {
    e.stopPropagation();
  };
  

  useEffect(() => {
    // Call the scrollToTop function whenever the currentSlideIndex changes
    scrollToTop();
  }, [currentSlideIndex]);

  /*
  useEffect(() => {
    // Disable scrolling on the body when the popup is open
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling on the body when the popup is closed
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  */

  const renderSlide = (slide) => {
    const { headline1, headline2, headline3, headline4, headline5, headline6, headline7, headline8, headline9, headline10, text1, text2, text3, text4, text5, text6, text7, text8, text9, text10, li1h, li2h, li3h, li4h, li5h, li1, li2, li3, li4, li5, image1, next_p, next } = slide;
    return (
      <div key={headline1} className="slide">
        <h1 className="FOBM__h1__GT" id="FOBM__h1__GT">{headline1}</h1>
        <p className="FOBM__intro__p">{text1}</p>
        <h2 className="FOBM__h2__GT">{headline2}</h2>
        <p>{text2}</p>
        <h2 className="FOBM__h2__GT">{headline3}</h2>
        <p>{text3}</p>
        <h2 className="FOBM__h2__GT">{headline4}</h2>
        <p>{text4}</p>
        <h2 className="FOBM__h2__GT">{headline5}</h2>
        <p>{text5}</p>
        <h2 className="FOBM__h2__GT">{headline6}</h2>
        <p>{text6}</p>
        <h2 className="FOBM__h2__GT">{headline7}</h2>
        <p>{text7}</p>
        <h2 className="FOBM__h2__GT">{headline8}</h2>
        <p>{text8}</p>
        <h2 className="FOBM__h2__GT">{headline9}</h2>
        <p>{text9}</p>
        <h2 className="FOBM__h2__GT">{headline10}</h2>
        <p>{text10}</p>
        <ul className="FOBM__slider__ul">
        {li1 !== "" && <li><span className="li-headline">{li1h}</span> {li1}</li>}
        {li2 !== "" && <li><span className="li-headline">{li2h}</span> {li2}</li>}
        {li3 !== "" && <li><span className="li-headline">{li3h}</span>{li3}</li>}
        {li4 !== "" && <li><span className="li-headline">{li4h}</span> {li4}</li>}
        {li5 !== "" && <li><span className="li-headline">{li5h}</span> {li5}</li>}
        </ul>
        <div>{image1}</div>
        <p className="FOBM__NEXT__DIV"><span className="FOBM__next">{next_p}</span> {next}</p>
    
      </div>
    
    );
  };


  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-container" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header">
          <div className="gpt3__features-container__feature-title">
            <div></div>
            <h1 className="h1-to-top">{title}</h1>
          </div>
          <button className="FOBM__PopupContent__closeButtonOne" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" className="bi-bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>
        <div className="slide-container">
          {renderSlide(slides[currentSlideIndex])}
          <div className="slider-controls">
            <button className="slider-btn-prev" onClick={handlePrevSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi-bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
            </button>
            <button className="slider-btn-next" onClick={handleNextSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi-bi-arrow-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupContent;
