import React, { useEffect } from 'react';
import './Timebooking.css';

const Timebooking = () => {
  useEffect(() => {
    // Load Calendly script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="timebooking">
{/*
        <div className="header">
            <h1> Book a 30 minute meeting</h1>
            <p>Let's have a chat about goals</p>
        </div>

        */}
  
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/fullofbeansmedia?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=white&text_color=black&primary_color=blue"
      
      ></div>
    </div>
  );
};

export default Timebooking;
