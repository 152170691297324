import React from 'react';
import ReactDOM from 'react-dom';
import Login from './pages/login/Login';
import Privacy from './pages/privacy/Privacy';
import Timebooking from './pages/timebooking/Timebooking';

import App from './App';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
    {
        path: "dashboard",
        element: <Login />,
      },
      {
      path: "privacy-policy",
      element: <Privacy />,
    },
    {
        path: "timebooking",
        element: <Timebooking />,
      },
  ]);

ReactDOM.render(
    <RouterProvider router={router} />, document.getElementById('root'));
