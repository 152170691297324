import React, { useEffect } from 'react';
import { Footer, Blog, Possibility, Features, Services, Scroll, Intro, Header, Contact, Dashboard, Consent, Foba, Country, Spk } from './containers';
import { CTA, Brand, Navbar, Hmv } from './components';
import './App.css';


const App = () => {




  return (
    <div className="App">
           {/*<Intro  />*/} 
 
    <div className="smaller">


      < Consent />
      < Country />
        <Navbar />
        <Header  />
        <Spk  />
       <Services />

 
      <Features />
   

      <Foba />
      <Blog />

      <Contact />
   
     
      </div>
    <Footer />
 
  
    </div>
  );
};

export default App;
