import React, { useState, useEffect, useRef } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom'
import './navbar.css';
import anotherlogo from '../../assets/tablogo.png'; // Update the path to your bg.png
import Animate from '../../containers/animate/Animate';
import Services from '../../containers/services/Services';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navbarRef = useRef(null);

  

  useEffect(() => {
    const knap = document.getElementById("main-contact-button-v1");
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // The Navbar is inside the viewport
       
 
     knap.style.display = 'none';
      } else {
        knap.style.display = 'flex';
      }
    });
  
    if (navbarRef.current) {
      observer.observe(navbarRef.current);
    }
  
    return () => {
      if (navbarRef.current) {
        observer.unobserve(navbarRef.current);
      }
    };
  }, []);

  return (
    <div className="navbar" >
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Animate>
        <img id="another-logo" src={anotherlogo} alt="Background" />
        </Animate>
        </div>
        <div className="navbar-links_container">
          <Animate><p id="nav-links" ><a href="#services" ref={navbarRef}>Services</a></p></Animate>
          <Animate><p id="nav-links"><a href="#about-us-header">About Us</a></p></Animate>
          <Animate><p id="nav-links"><Link to="/dashboard">Dashboard login</Link></p></Animate>
          <Animate><p id="nav-links-academy"><a href="#full-of-beans-academy">Academy</a></p></Animate>
        
        </div>
      </div>
      <div className="navbar-sign" >
       {/* <button id="main-contact-button" type="button" ><a href="#contact">Contact Us</a></button>*/}
      </div>
      <div ></div>
    </div>
   
  );
};

export default Navbar;
