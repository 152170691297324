import React, { useEffect } from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import Animate from '../animate/Animate';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import './blog.css';

const Blog = () => {
  useEffect(() => {
    // Load Twitter widget script asynchronously
    const twitterScript = document.createElement('script');
    twitterScript.src = 'https://platform.twitter.com/widgets.js';
    twitterScript.async = true;
    document.body.appendChild(twitterScript);

    // Ensure the script is loaded before rendering the Twitter blockquote
    twitterScript.onload = () => {
      // Render Twitter blockquote here
    };

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(twitterScript);
    };
  }, []);

  return (
    <div className="b__blog section__padding" id="blog">
      <div className="frame">
      <Animate>
      <div className="b__blog-heading">
        <h1 className="gradient__text">We like to share on Social Media<br /> </h1>
      </div>
      </Animate>
      <div className="b__blog-container">
      <Animate>
        <div className="b__blog-container_groupA">
          
        <blockquote className="twitter-tweet">
                <p lang="en" dir="ltr">
                  Have you explored the Google Transparency Center? <br /><br />With just a few clicks, you can gain insight into advertisers&#39; advertisements and their ad campaigns spanning across all of Google&#39;s platforms. <a href="https://t.co/RfBmuoKzy0">pic.twitter.com/RfBmuoKzy0</a>
                </p>
                &mdash; Full of Beans Media (@FOBM__) <a href="https://twitter.com/FOBM__/status/1698696183550459979?ref_src=twsrc%5Etfw">September 4, 2023</a>
              </blockquote>
          

        </div>
        </Animate>
        <div className="b__blog-container_groupB">
          {/* Embed the Facebook post iframe */}
          <Animate>
          <iframe
             className="facebook__post"
             src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffullofbeansmedia%2Fposts%2Fpfbid02EzyNtn2gp2vwNirUrvtxQEYQWgaB2tqkcGNYimTKSt1A8h2Fk6F5xBiAne4mdETnl&width=500&show_text=true&height=501&appId"
          
               style={{
                border: 'none',
                overflow: 'hidden',
                color: '#fff', // Set the text color to white or your desired color
                backgroundColor: 'white',
              }}
               scrolling="no"
               frameBorder="0"
               allowFullScreen="true"
               allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
               data-dark="true">
            </iframe>
            </Animate>
            {/* Embed the LinkedIn post iframe */}
            <Animate>
          <iframe
            className="facebook__post"
            src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7105222510715031553"
            height="373"
            width="300px"
            frameBorder="0"
            allowFullScreen=""
            title="Embedded Post"
          ></iframe>
           </Animate>

    
           </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
