import React, { useState, useEffect, useRef } from 'react';
import fobaVideo from '../../assets/intro.mp4'; 
import fobaCover from '../../assets/intro-cover.jpg'; 

import Animate from '../../containers/animate/Animate';
import './foba.css';


const Foba = () => {

  const videos = document.querySelectorAll('.academy-gif-class');
  if (videos.length > 0) {
    videos.forEach(video => {
      if (video.autoplay !== undefined) {
        video.play();
      } else {
        // Add a play button or handle user interaction to play the video
      }
    });
  } else {
    console.warn("No elements found with class 'academy-gif-class'");
  }
  
  
  
    return (
      <section className="full-of-beans-academy section__margin" id="full-of-beans-academy">
         <Animate><h1 className=''>Full of Beans Academy</h1></Animate>
      <Animate><div className="foba">
      <Animate><video className="foba-video" src={fobaVideo} controls controlsList='nodownload' poster={fobaCover}></video></Animate>

       <Animate>
          <div className="foba-elements">

            <h2 className="foba-elements-h2">Explore Our Academy Features</h2>

           <div className='foba-service' id="free-lessons">

           <svg xmlns="http://www.w3.org/2000/svg" class="all-icons" id="icon-1"  viewBox="0 0 16 16">
            <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
           </svg>
           
           <div>
           <h2 className="line-p">Free Lessons</h2>
           <p className="line-pp">Get started with our free video lessons.</p>
           </div>
           </div>

           <div className='foba-service' id="free-courses">
           <svg xmlns="http://www.w3.org/2000/svg" class="all-icons"   id="icon-2" viewBox="0 0 16 16">
            <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9z"/>
          </svg>

          <div>
           <h2 className="line-p">Free Courses</h2>
           <p className="line-pp">The courses you need for free.</p>
           </div>


           </div>

           <div className='foba-service' id="articles">
           <svg xmlns="http://www.w3.org/2000/svg" class="all-icons"  id="icon-3"  viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
          </svg>

          <div>
           <h2 className="line-p">Articles</h2>
           <p className="line-pp">Read and discover useful marketing knowlegde.</p>
           </div>

           </div>

           <div className='foba-service' id="Resources">
           <svg xmlns="http://www.w3.org/2000/svg" class="all-icons"  id="icon-4" viewBox="0 0 16 16">
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
          </svg>

          <div>
           <h2 className="line-p">Resources</h2>
           <p className="line-pp">Review our useful digital marketing resources.</p>
           </div>

           </div>

           <div className='foba-service' id="paid-courses">
           <svg xmlns="http://www.w3.org/2000/svg" class="all-icons" id="icon-5" viewBox="0 0 16 16">
            <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
            <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
          </svg>

          <div>
          <h2 className="line-p">Paid Courses</h2>
           <p className="line-pp">Become a digital marketing specialist.</p>
           </div>

           </div>
           
           <a href='https://fullofbeansmedia.com/academy/'>
           <div className='cta-button'>Visit Our Academy
           <svg xmlns="http://www.w3.org/2000/svg" class="button-arrow" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"></path>
                      </svg>
            </div>
           </a>
        

          </div>

       </Animate>

     

     

      </div></Animate>
      </section>
    );
  };
  
  export default Foba;
  