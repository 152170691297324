import React, { useEffect, useState } from 'react';
import './Consent.css';
import fobmlogo from '../../assets/tablogo.png';


const CookieConsent = () => {
  const [statisticsCookie, setStatisticsCookie] = useState(() => {
    const storedStatisticsCookie = localStorage.getItem('statisticsCookie');
    return storedStatisticsCookie === 'true' || storedStatisticsCookie === null;
  });

  const [marketingCookie, setMarketingCookie] = useState(() => {
    const storedMarketingCookie = localStorage.getItem('marketingCookie');
    return storedMarketingCookie === 'true' || storedMarketingCookie === null;
  });

  const [cookieAccepted, setCookieAccepted] = useState(false);

  const updateGoogleTag = (statisticsConsent, marketingConsent) => {
    let consentParams;
  
    if (statisticsConsent && !marketingConsent) {
      consentParams = {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
      };
    } else if (marketingConsent && !statisticsConsent) {
      consentParams = {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
      };
    } else if (marketingConsent && statisticsConsent) {
      consentParams = {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      };
    } else {
      consentParams = {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
      };
    }
  
   // console.log('Consent Mode Parameters:', consentParams);
   //console.log('Marketing Cookies Accepted:', marketingConsent);
    //console.log('Statistical Cookies Accepted:', statisticsConsent);
  
    // Actual gtag function call
    window.dataLayer = window.dataLayer || [];
    window.gtag('consent', statisticsConsent || marketingConsent ? 'update' : 'default', consentParams);
  };
  

  const handleSave = () => {
    // Get the current checkbox values
    const statisticsCheckbox = document.getElementById("statisticalCheckbox");
    const marketingCheckbox = document.getElementById("marketingCheckbox");

    // Check if the checkboxes exist and are checked
    const newStatisticsCookie = statisticsCheckbox ? statisticsCheckbox.checked : false;
    const newMarketingCookie = marketingCheckbox ? marketingCheckbox.checked : false;

    // Store the user's choices in localStorage
    localStorage.setItem("statisticsCookie", newStatisticsCookie.toString());
    localStorage.setItem("marketingCookie", newMarketingCookie.toString());

    // Update Google Tag based on user's choices
    updateGoogleTag(newStatisticsCookie, newMarketingCookie);

    // Set cookieAccepted to true
    localStorage.setItem("cookieAccepted", "true");

    // Hide the cookie consent box
    const cookieConsentBox = document.getElementById("cookieConsentBox");
    if (cookieConsentBox) {
        cookieConsentBox.style.display = "none";
    }

    // Show the accepted message box
    const acceptedMessage = document.getElementById("accepted-message");
    if (acceptedMessage) {
        acceptedMessage.style.display = "block";
    }

    // Add a data layer event for the consent settings change
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'consentSettingsChange',
        'statisticsCookie': newStatisticsCookie,
        'marketingCookie': newMarketingCookie
    });
}


  

  const showCookieSettings = () => {
    const storedStatisticsCookie = localStorage.getItem('statisticsCookie');
    const storedMarketingCookie = localStorage.getItem('marketingCookie');
  
    const newStatisticsCookie = storedStatisticsCookie === 'true';
    const newMarketingCookie = storedMarketingCookie === 'true';
  
    setStatisticsCookie(newStatisticsCookie);
    setMarketingCookie(newMarketingCookie);
  
    if (newStatisticsCookie && newMarketingCookie) {
      setCookieAccepted(true);
    } else {
      setCookieAccepted(false);
    }
  
    const cookieConsentBox = document.getElementById('cookieConsentBox');
    const acceptedMessage = document.getElementById('accepted-message');
  
  
      if (cookieAccepted) {
        cookieConsentBox.style.display = 'block';
        acceptedMessage.style.display = 'none';
      } else {
        cookieConsentBox.style.display = 'block';
        acceptedMessage.style.display = 'none';   
      }
    
  };
  

  useEffect(() => {
    const loadGoogleTag = () => {
      const statisticsCookieStored = localStorage.getItem('statisticsCookie');
      const marketingCookieStored = localStorage.getItem('marketingCookie');
      const cookieAcceptedStored = localStorage.getItem('cookieAccepted') === 'true';

      if (statisticsCookieStored === 'true' || marketingCookieStored === 'true') {
        updateGoogleTag(statisticsCookieStored === 'true', marketingCookieStored === 'true');
      } else {
        updateGoogleTag(false, false);
      }

      const cookieConsent = document.getElementById('cookieConsentBox');
      const acceptedMessage = document.getElementById('accepted-message');

      if (cookieAcceptedStored) {
        setCookieAccepted(true);
        if (cookieConsent && acceptedMessage) {
          cookieConsent.style.display = 'none';
          acceptedMessage.style.display = 'block';
        }
      } else {
        setCookieAccepted(false);
        if (cookieConsent && acceptedMessage) {
          cookieConsent.style.display = 'block';
          acceptedMessage.style.display = 'none';
        }
      }

     // console.log('Consent Mode Parameters:', { statisticsCookie: statisticsCookieStored, marketingCookie: marketingCookieStored });
     // console.log('Marketing Cookies Accepted:', marketingCookieStored);
      //console.log('Statistical Cookies Accepted:', statisticsCookieStored);
    };

    loadGoogleTag();

    const saveButton = document.getElementById('saveButton');
    saveButton.addEventListener('click', handleSave);

    return () => {
      saveButton.removeEventListener('click', handleSave);
    };
  }, []);
  return (
    <div className="cookie-container">
      <div className="cookie-consent-overlay" id="cookieConsentBox" style={{ display: cookieAccepted ? 'none' : 'block' }}>
        <div className="cookie-intro-box">
         
         
          <h1 id="h1-cookie-intro-v1">Full of Beans <span className="media-span">Media</span></h1>
         
          <div className="logo-name">
          <h1 id="h1-cookie-intro-v2" className="letter-animation">
            Value Your Privacy
          </h1>
      
          </div>
        </div>
        <div className="cookie-consent" id="">
          <h1>Cookie Consent</h1>
          <p className="cookie-p">
            This website uses cookies to ensure you get the best experience. Please review and select your preferences
            before you continue.
          </p>
          <div id="cookieOptions">
            <label className="custom-checkbox">
              <input type="checkbox" id="essentialCheckbox" checked disabled />
              <span className="checkmark">
                <svg xmlns="http://www.w3.org/2000/svg" className="cookie-tick" id="essentialCheckbox-icon" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              </span>
              <p className="check-p">Essential</p>
            </label>
            <label className="custom-checkbox">
              <input type="checkbox" id="statisticalCheckbox" checked={statisticsCookie} onChange={() => setStatisticsCookie(!statisticsCookie)} />
              <span className="checkmark">
                <svg xmlns="http://www.w3.org/2000/svg" className="cookie-tick" id="statisticalCheckbox-icon" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              </span>
              <p className="check-p">Statistical</p>
            </label>
            <label className="custom-checkbox">
              <input type="checkbox" id="marketingCheckbox" checked={marketingCookie} onChange={() => setMarketingCookie(!marketingCookie)} />
              <span className="checkmark">
                <svg xmlns="http://www.w3.org/2000/svg" className="cookie-tick" id="marketingCheckbox-icon" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              </span>
              <p className="check-p">Marketing</p>
            </label>
          </div>
          
          <button id="saveButton" onClick={handleSave}>
            Save Preferences
          </button>
        </div>
      </div>

      {/* Accepted Message Box */}
      <div id="accepted-message" style={{ display: cookieAccepted ? 'block' : 'none' }}>
        <div id="change-cookie-settings" onClick={showCookieSettings}>
          {/* Icon SVG */}
          <div className="circle-icon">
            <svg xmlns="http://www.w3.org/2000/svg" className="cookie-icon" viewBox="0 0 16 16">
              <path d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
              <path d="M8 0a7.96 7.96 0 0 0-4.075 1.114q-.245.102-.437.28A8 8 0 1 0 8 0m3.25 14.201a1.5 1.5 0 0 0-2.13.71A7 7 0 0 1 8 15a6.97 6.97 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.97 6.97 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A7 7 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.97 6.97 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7 7 0 0 1-2.51 2.224Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
